import React from 'react';
import './Section1.css';
import ShapeWhite from '../../assets/images/ShapeWhite.png';

function Section({ bgColor, text, titleText1, titleText2, imageSrc, t, language }) {
  const font = language === 'AR' ? 'Thameen' : 'Cadiz';

  return (

    <div className='section' style={{ backgroundColor: bgColor, fontFamily: font }}>
      <div className="section-container" >
        <div className='section1-text-container1'>
            <p className="section-text hidden slide-in-top">{t('section1Text')}</p>

        </div>
        {
          language === 'AR'
          &&
          <div className='section1-text-container2'>
            <p className="section-text hidden slide-in-top">{t('section1TextAr1')}</p>
            <p className="section-text hidden slide-in-top" style={{ fontFamily: 'Cadiz' }}>{t('brandName')}</p>
            <p className="section-text hidden slide-in-top">{t('section1TextAr2')}</p>
          </div>
        }

          <div className='section-container2 section1-image main-background-image'>

            {language === 'AR' &&
              (
                <div>
                  <img src={ShapeWhite} className='section1-shape'></img>
                  <div className='section1-flex-text-container'>
                    <p className='section1-text hidden slide-in-top'>{titleText1}</p>
                    <p className='section1-text hidden slide-in-top'>{titleText2}</p>
                  </div>
                </div>
              )
            }
            {language === 'EN' &&
              (
                <div>
                  <img src={ShapeWhite} className='section1-shape-en'></img>
                  <div className='section1-flex-text-container-en'>
                    <p className='section1-text-en hidden slide-in-top'>{titleText1}</p>
                  </div>
                </div>
              )
            }

          </div>

        <div className="section2-title-shape-container">

        </div>

      </div>
    </div>

  );
}

export default Section;