import React from 'react';
import './Section7.css';

import Image1 from '../../assets/images/Section7_Image1.png';
import Image1Ar from '../../assets/images/contact_ar.png';
import ContactForm from '../form_monday/ContactForm';


function Section7({ bgColor, titleText, titleText2, centerText, centerText2, centerText3, extraTexts, language, t, locale }) {

    return (
        <div id="contactForm" className="section7" style={{ backgroundColor: bgColor  }}>
            <div className='section7-rectangle'></div>

            <div className="section7-container" >

                <div className='section7-image-container'>
                    {
                        locale === 'AR' ?
                            <img src={Image1Ar} alt='Download 1' className='section7-image'></img>
                            : <img src={Image1} alt='Download 1' className='section7-image'></img>
                    }
                </div>

                <ContactForm t={t} locale={locale}/>

            </div>

        </div>
    );
}

export default Section7;