import React from 'react';
import './Section5.css';

import Image1 from '../../assets/images/housekeeping.png';
import Image2 from '../../assets/images/hosting.png';
import Image3 from '../../assets/images/vetting.png';
import Image4 from '../../assets/images/photography.png';

const images = [
    Image1,
    Image2,
    Image3,
    Image4
]


function Section5({ bgColor, t, locale }) {
    const imagesCaptions = [
        t('section5ImageCaption1'),
        t('section5ImageCaption2'),
        t('section5ImageCaption3'),
        t('section5ImageCaption4'),
    ]
    const textAlignment = locale === 'AR' ? 'right' : 'center';
    return (
        <div className="section5" style={{ backgroundColor: bgColor }}>
            <div className="section5-container"  >

                {locale === 'AR' ?

                    <div className='section5-text-container section5-centered-container'>
                        <div>
                            <p style={{ textAlign: textAlignment }} className='section5-text section5-text-align-left hidden'>{t('section5TitleTextA')}</p>
                            <p style={{ textAlign: textAlignment }} className='section5-text section5-text-align-left hidden'>{t('section5TitleTextB')}</p>
                        </div>
                        <div className='section5-row-container'>
                            <p style={{ textAlign: textAlignment }} className='section5-text hidden'>{t('section5TitleTextC')}</p>

                            <div class="checkmark-container">
                                <div class="checkmark hidden"></div>
                            </div>

                            <div className='section5-vertical-line hidden '></div>
                        </div>


                        <div className='section5-row-container'>
                            <p style={{ textAlign: textAlignment }} className='section5-text hidden slide-in-top'>{t('section5TitleTextD')}</p>

                            <div class="checkmark-container">
                                <div class="checkmark hidden"></div>
                            </div>

                            <div className='section5-vertical-line hidden '></div>
                        </div>


                    </div>
                    :
                    <div className='section5-text-container section5-centered-container'>
                        <p style={{ textAlign: textAlignment }} className='section5-text hidden'>{t('section5TitleText')}</p>

                        <p style={{ textAlign: textAlignment }} className='section5-text hidden'>{t('section5CenterText')}</p>
                    </div>
                }

                <div className='section5-gallery-container'>
                    {images && images.map((imageUrl, index) => (
                        <div className='section5-image-frame'>
                            <p className='section5-image-caption hidden' >{imagesCaptions[index]}</p>
                            <img key={index} src={imageUrl} className='section5-image'></img>
                        </div>
                    ))}
                </div>


            </div>


        </div>
    );
}

export default Section5;