import React from 'react';
import './Section8.css';

import Footer from '../footer/Footer';

function Section8({ bgColor, centerText, centerText2, centerText3, extraTexts, language,  t }) {
    return (
        <div className="section8" style={{ backgroundColor: bgColor }}>

            <div className="section8-container" >
                <Footer bgColor={"#71192E"} centerText={centerText}  centerText2={centerText2} centerText3={centerText3} t={t}/>
            </div>
        </div>
    );
}

export default Section8;