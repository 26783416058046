
import { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n/i18n';
import { useTranslation } from 'react-i18next';

import Navbar from './components/Navbar/Navbar';
import Section from './components/section1/Section1';
import Section2 from './components/section2/Section2';
import Section3 from './components/section3/Section3';
import Section4 from './components/section4/Section4';
import Section5 from './components/section5/Section5';
import Section6 from './components/section6/Section6';
import Section7 from './components/section7/Section7';
import Section8 from './components/section8/Section8';

import EmailjsInit from './components/emailjs/emailjs';

import { ToastContainer } from 'react-toastify';
import SocialsBar from './components/SocialsBar/SocialsBar';

function App() {
  const [locale, setLocale] = useState('AR');
  const { t } = useTranslation();

  const font = locale === 'AR' ? 'Thameen' : 'Cadiz';

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        }
      });
    });

    const hiddenElements = document.querySelectorAll('.hidden');
    hiddenElements.forEach((el) => observer.observe(el));

    // Cleanup: unobserve the elements when component is unmounted
    return () => {
      hiddenElements.forEach((el) => observer.unobserve(el));
    };
  }, [locale]);

  return (
    <I18nextProvider i18n={i18n}>
      <div className="App" style={{ fontFamily: font }}>

        <Navbar t={t} language={[locale, setLocale]} />
        <SocialsBar />

        <Section bgColor={"#F7EFEE"} titleText1={t('section2TextA')} titleText2={t('section2TextB')} text={t('section1Text')} t={t} language={locale} />
        <Section2 bgColor={"#F68F78"} titleText={t('section2TextA')} centerText={t('section2CenterText')} locale={locale} />
        <Section3 bgColor={"#283755"} titleText={t('section3TitleText')} centerText={t('section3CenterText')} />
        <Section4 bgColor={"#F68F78"} centerText1={t('section4CenterTextA')} centerText2={t('brandName')} centerText3={t('section4CenterTextB')} locale={locale} />
        <Section5 bgColor={"#F1F3E3"} t={t} locale={locale} />
        <Section6
          bgColor={"#FEF6F5"}
          titleText={t('section6TitleText')}
          titleText2={t('section6TitleText2')}
          centerText={t('section6CenterText')}
          centerText2={t('section6CenterText2')}
          centerText3={t('section6CenterText3')}
          language={locale}
          t={t}
        />

        <Section7 bgColor={"#FFF"} t={t} locale={locale} />

        <Section8 bgColor={"#F68F78"} centerText={t('section8Text1')} centerText2={t('section8Text2')} centerText3={t('section8Text3')} t={t} />
        <ToastContainer />
      </div>
    </I18nextProvider>
  );
}

export default App;
