import React from 'react';
import './Section2.css';
import AboutImage1 from '../../assets/images/About_Image_1.png';
import AboutImage2 from '../../assets/images/About_Image_2.png';
import AboutImage3 from '../../assets/images/About_Image_3.png';

function Section2({ bgColor, titleText, centerText, locale }) {

    return (
        <div className='section-2' style={{ backgroundColor: bgColor }}>
            <div className='section2-container'>
                

                <p className="section2-text hidden slide-in-bottom">{centerText}</p>

                <div className='section2-image-gallery'>
                    <img src={AboutImage1} alt={"Gallery image 1"} className='gallery-image'></img>
                    <img src={AboutImage3} alt={"Gallery image 3"} className='gallery-image'></img>
                    <img src={AboutImage2} alt={"Gallery image 2"} className='gallery-image'></img>

                </div>
            </div>


        </div>



    );
}

export default Section2;