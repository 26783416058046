import React from 'react';
import './Section6.css';
import { useRef } from 'react';

import Image1EN from '../../assets/images/section6-image1-en.png';
import Image2EN from '../../assets/images/Section6-image2-en.png';
import Image1AR from '../../assets/images/Section6-image1-ar.png'; 
import Image2AR from '../../assets/images/Section6-image2-ar.png';

function Section6({ bgColor, titleText, titleText2, centerText, centerText2, centerText3, extraTexts, language, t }) {
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);

    const handleDownload1 = () => {
        ref1.current.click();
    }
    const handleDownload2 = () => {
        ref2.current.click();
    }
    const handleDownload3 = () => {
        ref3.current.click();
    }
    const handleDownload4 = () => {
        ref4.current.click();
    }


    return (
        <div id="services" className="section6" style={{ backgroundColor: bgColor }}>
            <div className="section6-container" >
                {
                    language === 'AR' ?
                        <>
                            <p className='section6-title-text'>{titleText}</p>

                            <p className='section6-text section6-text-border hidden slide-in-top'>{centerText}</p>
                        </>
                        :
                        <>
                            <p className='section6-title-text hidden slide-in-top'>{t('section6TitleText')}</p>
                        </>
                }

                <div className='section6-image-gallery-container'>
                    <div className='section6-image-container'>
                        <img src={Image1EN} alt='Download 1' className='section6-image'></img>

                        <div style={{ width: '100%' }}>
                            <a
                                href="/profile_en.pdf"
                                download="profile_en.pdf"
                                ref={ref1}
                                style={{ display: 'none' }}
                            >Download</a>
                            <div onClick={handleDownload1} className='section6-download-button'><span className='section6-download-here-text'>{t('downloadButtonText')}</span></div>
                        </div>
                    </div>
                    <div className='section6-image-container'>
                        <img src={Image1AR} alt='Download 2' className='section6-image'></img>

                        <div style={{ width: '100%' }}>
                            <a
                                href="/profile_ar.pdf"
                                download="profile_ar.pdf"
                                ref={ref2}
                                style={{ display: 'none' }}
                            >Download</a>
                            <div onClick={handleDownload2} className='section6-download-button'><span className='section6-download-here-text'>{t('downloadButtonText')}</span></div>
                        </div>
                    </div>

                </div>

            </div>

            <div className="section6-container" >
                {
                    language === 'AR' ?
                        <>
                            <p className='section6-title-text hidden slide-in-top'>{titleText2}</p>

                            <p className='section6-text section6-text-border hidden slide-in-top'>{centerText2}</p>
                        </>
                        :
                        <>
                            <p className='section6-title-text hidden slide-in-top'>{titleText2}</p>
                        </>
                }

                <p className='section6-text2'>{centerText3}</p>

                <div className='section6-image-gallery-container'>
                    <div className='section6-image-container'>
                        <img src={Image2EN} alt='Download 1' className='section6-image'></img>
                        <div style={{ width: '100%' }}>
                            <a
                                href="packages_en.pdf"
                                download="packages_en.pdf"
                                ref={ref3}
                                style={{ display: 'none' }}
                            >Download</a>
                            <div onClick={handleDownload3} className='section6-download-button'><span className='section6-download-here-text'>{t('downloadButtonText')}</span></div>

                        </div>
                    </div>
                    <div className='section6-image-container'>
                        <img src={Image2AR} alt='Download 2' className='section6-image'></img>
                        <div style={{ width: '100%' }}>
                            <a
                                href="packages_ar.pdf"
                                download="packages_ar.pdf"
                                ref={ref4}
                                style={{ display: 'none' }}
                            >Download</a>
                            <div onClick={handleDownload4} className='section6-download-button'><span className='section6-download-here-text'>{t('downloadButtonText')}</span></div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}

export default Section6;