import React from 'react';
import './Section3.css';

import WorldMap from '../../assets/images/WorldMap.png';

function Section3({ bgColor, titleText, centerText }) {
    return (
        <div id="aboutus" className="section-3" style={{ backgroundColor: bgColor }}>
            <img src={WorldMap} className='section3-map'></img>
            <div className="section3-container" >
                <p className='section3-text hidden slide-in-top'>{titleText}</p>
                <p className='section3-text hidden slide-in-top'>{centerText}</p>
            </div>
            

        </div>
    );
}

export default Section3;