import React, { useState, useRef, useEffect } from 'react';
import './Navbar.css';
import GuestReadyLogo from '../../assets/images/GuestReadyLogo.png';
import i18n from '../../i18n/i18n';

function Navbar({ t, language }) {

    const [locale, setLocale] = language;

    const [dropdownOpen, setDropDownOpen] = useState(false);
    const [currentLanguage, setCurrentLanguage] = useState('AR');

    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropDownOpen(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        }
    }, []);


    const switchLanguage = (targetLanguage) => {
        setLocale(targetLanguage);
        setCurrentLanguage(targetLanguage);

        i18n.changeLanguage(targetLanguage.toLowerCase());
        setDropDownOpen(false);

    }

    return (
        <nav className="navbar fixed-navbar">
            <div className='navbar-rectangle'></div>
            <div className="navbar-container">
                <div className="logo">
                    <img src={GuestReadyLogo} alt="Website Logo" className='website-logo'></img>
                </div>

                <ul className="nav-menu">
                    <li className="nav-item">
                        <a href="#contactForm" className="nav-link">
                            {t('navbarContactUs')}
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#services" className="nav-link">
                            {t('navbarServices')}
                            <span className="arrow-down"></span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="#aboutus" className="nav-link">{t('navbarAboutUs')}</a>
                    </li>

                    <li className="nav-item language-dropdown" ref={dropdownRef}>
                        <span
                            className="language-switcher"
                            onClick={() => setDropDownOpen(!dropdownOpen)}
                        >
                            {currentLanguage}<span className="arrow-down"></span>
                        </span>
                        {dropdownOpen && (
                            <div className="languages-list">
                                <span onClick={() => { switchLanguage('EN') }}>EN</span>
                                <span onClick={() => { switchLanguage('AR') }}>AR</span>
                            </div>
                        )}
                    </li>
                </ul>


            </div>
        </nav>
    );
}

export default Navbar;
