import React from 'react';
import './Section4.css';

import ServicesImage from '../../assets/images/ServicesImage.png';
import ServicesImage2 from '../../assets/images/ServicesImage2.png';

function Section4({ bgColor, titleText, centerText1, centerText2, centerText3, locale }) {
    return (
        <div className="section4" style={{ backgroundColor: bgColor }}>
            {locale === 'AR' ?
                <div className='section4-text-container'>
                    <p className='section4-text hidden slide-in-top'>{centerText1}</p>
                    <p className='section4-text hidden slide-in-top'>{centerText2}</p>
                    <div className='section4-centered-container'>
                        <p className='section4-text hidden slide-in-top'>{centerText3}</p>

                    </div>
                </div>
                :
                <div className='section4-text-container'>
                    <div className='section4-centered-container'>
                        <p className='section4-title-text hidden slide-in-top'>{titleText}</p>
                        <p className='section4-text hidden slide-in-top'>{centerText1}</p>
                    </div>
                </div>
            }
            <img src={ServicesImage} className='section4-image'></img>
            <img src={ServicesImage2} className='section4-image2' ></img>

        </div>
    );
}

export default Section4;